import Vue from "vue";
import { AUTH_USER, AUTH_PRIVILEGES } from "@/constants/api";

class Auth {
  constructor() {
    this.user = null;
    this.privileges = null;
    this.privilegeMatcher = null;
  }

  get token() {
    return window.localStorage.getItem("auth_token");
  }

  set token(value) {
    window.localStorage.setItem("auth_token", value);
  }

  get isLoggedIn() {
    return !!(this.user && this.user.username);
  }

  logout() {
    this.token = null;
    window.location.href = "/";
  }

  verifyPrivilege(apiPath, method) {
    return this.privilegeMatcher && this.privilegeMatcher.test(`${method || "any"}\t${apiPath}`);
  }

  async fetchUser() {
    if (!this.token) return;
    window.axios.defaults.headers.common = {
      Authorization: `Bearer ${this.token}`,
    };
    try {
      this.user = (await window.axios.get(AUTH_USER)).data.data;
      this.privileges = (await window.axios.get(AUTH_PRIVILEGES)).data.data;

      const matcher = Object.entries(this.privileges || {})
        .map(([path, method]) => {
          method = method.join("|");
          path = path.replace(/\{[^}]+\}/g, "[^/]+");
          return `(^(${method}|any)\\t${path}$)`;
        })
        .join("|");
      this.privilegeMatcher = matcher && new RegExp(matcher);
    } catch (err) {
      //console.error(err);
      this.user = null;
      this.privileges = null;
    }
  }
}

const _auth = new Auth();

const Plugin = {
  install() {
    Vue.$auth = _auth;
    Object.defineProperties(Vue.prototype, {
      $auth: {
        get: () => _auth,
      },
    });
  },
};

window.auth = _auth;
Vue.use(Plugin);
