export const management = [
  {
    path: "configuration",
    name: "management.configuration",
    component: () => import("@/views/pages/dashboard/management/configuration"),
    meta: {
      gate: "auth",
      level: 500,
    },
  },
  {
    path: "status",
    name: "management.status",
    component: () => import("@/views/pages/dashboard/management/status"),
    meta: {
      gate: "auth",
      level: 500,
    },
  },
  {
    path: "logs",
    name: "management.logs",
    component: () => import("@/views/pages/dashboard/management/logs"),
    meta: {
      gate: "auth",
      level: 500,
    },
  },
  {
    path: "http-trace",
    name: "management.http-trace",
    component: () => import("@/views/pages/dashboard/management/httpTrace"),
    meta: {
      gate: "auth",
      level: 500,
    },
  },
];
