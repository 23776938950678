import Vue from "vue";
import axios from "axios";

const _axios = axios.create({
  baseURL: window.API_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 300000,
});

const _cache = {};

_axios.$get = async (url, config) => {
  if (!_cache[url]) {
    const resp = await _axios.get(url, config);
    _cache[url] = resp.data;
  }
  return _cache[url];
};

const Plugin = {
  install() {
    Vue.$axios = _axios;
    Object.defineProperties(Vue.prototype, {
      $axios: {
        get: () => _axios,
      },
    });
  },
};

window.axios = _axios;
Vue.use(Plugin);
