import Vue from "vue";
import "@/plugins/axios";
import "@/plugins/auth";
import "@/plugins/meta";
import "@/plugins/iziToast";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "@/store";
import DatetimePicker from "vuetify-datetime-picker";
import "@/mixins/helperMixin";
import "@/scss/custom.scss";

async function preProcess() {
  await window.auth.fetchUser();
}

Vue.use(DatetimePicker);

preProcess().finally(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
