import Vue from "vue";
import Router from "vue-router";
import { dashboardRoutes } from "@/router/dashboard";
import { userRoutes } from "@/router/dashboard/user";
import { resourceRoutes } from "@/router/dashboard/resource";
import { repositoryRoutes } from "@/router/dashboard/repository";
import { mfsRoutes } from "@/router/dashboard/mfs";
import { cbsRoutes } from "@/router/dashboard/cbs";
import { management } from "@/router/dashboard/management";
import { facelab } from "@/router/dashboard/facelab";
import { nidOCr } from "@/router/dashboard/nidOcr";
import { nidScraper } from "@/router/dashboard/nidScraper";
import { porichoy } from "@/router/dashboard/porichoy";
import { checker } from "@/router/dashboard/checker";
import { testRoutes } from "@/router/dashboard/test";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: () => import("@/views/layouts/home"),
    children: [
      {
        path: "",
        name: "home",
        redirect: "login",
        meta: {
          gate: "guest",
        },
      },
      {
        path: "login",
        name: "login",
        component: () => import("@/views/pages/home/login/index"),
        meta: {
          gate: "guest",
          layout: "simple",
        },
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("@/views/layouts/dashboard"),
    children: [
      ...dashboardRoutes,
      ...userRoutes,
      ...resourceRoutes,
      ...management,
      ...mfsRoutes,
      ...cbsRoutes,
      ...repositoryRoutes,
      ...facelab,
      ...nidOCr,
      ...nidScraper,
      ...porichoy,
      ...testRoutes,
      ...checker,
    ],
  },
  {
    path: "/500",
    name: "fiveHundred",
    component: () => import("@/views/pages/error/500"),
  },
  {
    path: "/404",
    name: "fourOFour",
    component: () => import("@/views/pages/error/404"),
  },
  {
    path: "*",
    name: "noMatch",
    component: () => import("@/views/pages/error/404"),
  },
];

const router = new Router({
  routes,
  mode: "hash", // "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // console.log("Resolving: " + to.name);
  if (to.name === "home") {
    if (window.auth.isLoggedIn) {
      next({ name: "dashboard" });
      return;
    } else {
      next({ name: "login" });
      return;
    }
  }
  if (to.meta) {
    if (to.meta.gate === "guest" && window.auth.isLoggedIn) {
      next({ name: "dashboard" });
      return;
    }
    if (to.meta.gate === "auth" && !window.auth.isLoggedIn) {
      next({
        name: "login",
        query: { goto: to.fullPath },
      });
      return;
    }
    next();
    return;
  }
  next();
});

export default router;
