export const facelab = [
  {
    path: "liveness-request",
    name: "liveness-request.index",
    component: () => import("@/views/pages/dashboard/face_lab/liveness_request/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "liveness-request/:requestId",
    name: "liveness-request.single",
    component: () => import("@/views/pages/dashboard/face_lab/liveness_request/view_single"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "match-request",
    name: "match-request.index",
    component: () => import("@/views/pages/dashboard/face_lab/match_request/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "match-request/:requestId",
    name: "match-request.single",
    component: () => import("@/views/pages/dashboard/face_lab/match_request/view_single"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "request-image",
    name: "request-image.index",
    component: () => import("@/views/pages/dashboard/face_lab/request_image"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "request-image/:md5sum",
    name: "request-image.single",
    component: () => import("@/views/pages/dashboard/face_lab/request_image/view_single"),
    meta: {
      gate: "auth",
    },
  },
];
