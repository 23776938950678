export const checker = [
  {
    path: "checker",
    name: "checker.index",
    component: () => import("@/views/pages/dashboard/checker/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "checker/view/:id",
    name: "checker.view",
    component: () => import("@/views/pages/dashboard/checker/view_single"),
    meta: {
      gate: "auth",
    },
  },
];
