import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { dashboardStates } from "@/store/modules/dashboardStates";
import { testStates } from "@/store/modules/testStates";

Vue.use(Vuex);

const vuexPersistence = new VuexPersistence({
  key: "kyc-portal",
  storage: window.localStorage,
  modules: [
    // "dashboard"
  ],
});

const store = new Vuex.Store({
  modules: {
    // loading: loadingState,
    dashboard: dashboardStates,
    test: testStates,
  },
  plugins: [vuexPersistence.plugin],
});

export default store;
