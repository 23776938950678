export const userRoutes = [
  {
    path: "users",
    name: "user.index",
    component: () => import("@/views/pages/dashboard/authentication/user/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "user/deleted",
    name: "user.deleted.index",
    component: () => import("@/views/pages/dashboard/authentication/user/deleted"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "user/create",
    name: "user.create",
    component: () => import("@/views/pages/dashboard/authentication/user/create"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "user/edit/:id",
    name: "user.edit",
    component: () => import("@/views/pages/dashboard/authentication/user/edit"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "user/view/:id",
    name: "user.view",
    component: () => import("@/views/pages/dashboard/authentication/user/view"),
    meta: {
      gate: "auth",
    },
  },
];
