export const nidOCr = [
  {
    path: "ocr-request",
    name: "ocr-request.index",
    component: () => import("@/views/pages/dashboard/nid_ocr/ocr_request/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "ocr-request/:md5sum",
    name: "ocr-request.single",
    component: () => import("@/views/pages/dashboard/nid_ocr/ocr_request/view_single"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "parse-request",
    name: "parse-request.index",
    component: () => import("@/views/pages/dashboard/nid_ocr/parse_request/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "parse-request/:id",
    name: "parse-request.single",
    component: () => import("@/views/pages/dashboard/nid_ocr/parse_request/view_single"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "nid-ocr-card",
    name: "nid-ocr-card.index",
    component: () => import("@/views/pages/dashboard/nid_ocr/nid_ocr_card/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "nid-ocr-card/:requestId",
    name: "nid-ocr-card.single",
    component: () => import("@/views/pages/dashboard/nid_ocr/nid_ocr_card/view_single"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "nid-ocr/billing",
    name: "billing.index",
    component: () => import("@/views/pages/dashboard/nid_ocr/billing/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "nid-ocr/billing/receipt",
    name: "billing.view",
    component: () => import("@/views/pages/dashboard/nid_ocr/billing/receipt"),
    meta: {
      gate: "auth",
    },
  },
];
