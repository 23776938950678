export const dashboardRoutes = [
  {
    path: "",
    name: "dashboard",
    component: () => import("@/views/pages/dashboard/index"),
    meta: {
      gate: "auth",
    },
  },
];
