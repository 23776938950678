export const nidScraper = [
  {
    path: "nid-scraper",
    name: "nid-scraper.index",
    component: () => import("@/views/pages/dashboard/voter_info/nid_scraper/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "nid-scraper/:requestId",
    name: "nid-scraper.single",
    component: () => import("@/views/pages/dashboard/voter_info/nid_scraper/view_single"),
    meta: {
      gate: "auth",
    },
  },
];
