export const resourceRoutes = [
  {
    path: "resource",
    name: "resource.index",
    component: () => import("@/views/pages/dashboard/authentication/resource/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "resource/edit/:id",
    name: "resource.edit",
    component: () => import("@/views/pages/dashboard/authentication/resource/edit"),
    meta: {
      gate: "auth",
    },
  },
];
