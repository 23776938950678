export const cbsRoutes = [
  {
    path: "cbs",
    name: "cbs.index",
    component: () => import("@/views/pages/dashboard/cbs/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "cbs/view/:id",
    name: "cbs.view",
    component: () => import("@/views/pages/dashboard/cbs/view_single"),
    meta: {
      gate: "auth",
    },
  },
];
