import Vue from "vue";
import moment from "moment";
import * as api from "@/constants/api";

const helperMixin = {
  methods: {
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
    formatDate(date) {
      return moment(new Date(date)).format("DD MMM, YYYY \n hh:mm:ss A");
    },
    verifyPrivilege(apiPath, method) {
      return this.$auth.verifyPrivilege(apiPath, method);
    },
    getUserRole() {
      return this.$auth.user.role;
    },
    // popular permission checks
    canShowUserInfo() {
      return this.verifyPrivilege(api.USER_LIST);
    },
    canShowNidOcrCard() {
      return this.verifyPrivilege(api.NID_OCR_CARDS);
    },
    canShowLivenessRequest() {
      return this.verifyPrivilege(api.FACE_LIVENESS_REQUESTS);
    },
    canShowMatchRequest() {
      return this.verifyPrivilege(api.FACE_MATCH_REQUESTS);
    },
    canShowOcrRequest() {
      return this.verifyPrivilege(api.OCR_REQUESTS);
    },
    canShowParseRequest() {
      return this.verifyPrivilege(api.PARSE_REQUESTS);
    },
    canShowFaceRequest() {
      return this.verifyPrivilege(api.FACE_REQUEST_IMAGES);
    },
    canShowPorichoyRequest() {
      return this.verifyPrivilege(api.PORICHOY_REQUESTS);
    },
  },
};

Vue.mixin(helperMixin);
