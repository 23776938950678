export const porichoy = [
  {
    path: "porichoy-request",
    name: "porichoy-request.index",
    component: () => import("@/views/pages/dashboard/voter_info/porichoy_request/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "porichoy-request/:voterInfoId/:requestId",
    name: "porichoy-request.single",
    component: () => import("@/views/pages/dashboard/voter_info/porichoy_request/view_single"),
    meta: {
      gate: "auth",
    },
  },
];
