export const repositoryRoutes = [
  {
    path: "repositories",
    name: "repository.index",
    component: () => import("@/views/pages/dashboard/repository/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "repository/terms",
    name: "repository.terms",
    component: () => import("@/views/pages/dashboard/repository/items/terms"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "repository/:item",
    name: "repository.item",
    component: () => import("@/views/pages/dashboard/repository/items/index"),
    meta: {
      gate: "auth",
    },
  },
];
