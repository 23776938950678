export const testStates = {
  namespaced: true,
  state: () => {
    return {
      lastUsedDevice: null,
      testNidInfoData: { nid: null, dob: null, porichoy: null, scraper: null },
      testNidOcrData: { backText: null, frontText: null },
      testMatchData: {},
      testLivenessData: {},
    };
  },
  getters: {
    getLastUsedDevice: (state) => state.lastUsedDevice,
  },
  mutations: {
    setLastUsedDevice(state, data) {
      state.lastUsedDevice = data;
    },
    setTestNidInfoData(state, data) {
      state.testNidInfoData = { ...state.testNidInfoData, ...data };
    },
    setTestNidOcrData(state, data) {
      state.testNidOcrData = { ...state.testNidOcrData, ...data };
    },
    setTestMatchData(state, data) {
      state.testMatchData = { ...state.testMatchData, ...data };
    },
    setTestLivenessData(state, data) {
      state.testLivenessData = { ...state.testLivenessData, ...data };
    },
  },
};
