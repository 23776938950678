export const mfsRoutes = [
  {
    path: "mfs",
    name: "mfs.index",
    component: () => import("@/views/pages/dashboard/mfs/index"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "mfs/view/:id",
    name: "mfs.view",
    component: () => import("@/views/pages/dashboard/mfs/view_single"),
    meta: {
      gate: "auth",
    },
  },
];
