import Vue from "vue";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

Vue.use(iziToast);
Vue.prototype.$iziToast = iziToast;

iziToast.settings({
  timeout: 3000,
  position: "topCenter",
});

iziToast.showError = (err, title) => {
  err = (err.response || {}).data || err.data || err;
  iziToast.error({
    title: title || "",
    message: err.message || err,
  });
};

iziToast.askConfirmation = (
  title = "Are you sure about that?",
  message = "This step is irreversible"
) =>
  new Promise((resolve) => {
    iziToast.question({
      timeout: 10000,
      close: true,
      overlay: true,
      title,
      message,
      position: "center",
      buttons: [
        [
          "<button><b>YES</b></button>",
          function (instance, toast) {
            instance.hide({ transitionOut: "fadeOut" }, toast, "yes");
          },
          true,
        ],
        [
          "<button>NO</button>",
          function (instance, toast) {
            instance.hide({ transitionOut: "fadeOut" }, toast, "no");
          },
        ],
      ],
      onClosed: function (instance, toast, closedBy) {
        resolve(closedBy === "yes");
      },
    });
  });

export default iziToast;
