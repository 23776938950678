export const testRoutes = [
  {
    path: "test/liveness",
    name: "test.liveness",
    component: () => import("@/views/pages/dashboard/test/liveness.vue"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "test/match",
    name: "test.match",
    component: () => import("@/views/pages/dashboard/test/match.vue"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "test/nid-ocr",
    name: "test.nid-ocr",
    component: () => import("@/views/pages/dashboard/test/nid_ocr.vue"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "test/nid-info-scraper",
    name: "test.nid-info-scraper",
    component: () => import("@/views/pages/dashboard/test/nid_info.vue"),
    meta: {
      gate: "auth",
    },
  },
  {
    path: "test/nid-info-porichoy",
    name: "test.nid-info-porichoy",
    component: () => import("@/views/pages/dashboard/test/nid_info.vue"),
    meta: {
      gate: "auth",
    },
  },
];
